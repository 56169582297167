//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from '@/plugins/eventBus';
import navbar from '../components/shared/navbar';
import accountDrawer from '../components/account/drawer';
import projectDrawer from '../components/project/drawer';
import profileDrawer from '../components/account/profileDrawer';

export default {
  name: 'Home',
  components: {
    navbar,
    projectDrawer,
    accountDrawer,
    profileDrawer
  },
  data() {
    return {
      showDrawer: true,
      showProjectDrawer: true,
      showProfileDrawer: true,
    };
  },
  watch: {
    $route() {
      eventBus.triggerProjectDrawer({status: false});
      eventBus.triggerAccountDrawer(false);
      eventBus.triggerProfileDrawer(false);
    },
  },
};
